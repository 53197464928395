import React from 'react'

function Navbar(props) {

    const onActiveChange = (page) => {
        props.onPageChange(page);
    }
    return (
        <nav className="navbar">

            <ul className="navbar-list">

                <li className="navbar-item">
                    <button className={`navbar-link  ${props.active === "About" ? "active" : ""}`} data-nav-link onClick={() => onActiveChange("About")}>About</button>
                </li>

                <li className="navbar-item">
                    <button className={`navbar-link ${props.active === "Skills" ? "active" : ""}`} data-nav-link onClick={() => onActiveChange("Skills")}>Skills</button>
                </li>

                <li className="navbar-item">
                    <button className={`navbar-link ${props.active === "Resume" ? "active" : ""}`} data-nav-link onClick={() => onActiveChange("Resume")}>Resume</button>
                </li>

                <li className="navbar-item">
                    <button className={`navbar-link ${props.active === "Portfolio" ? "active" : ""}`} data-nav-link onClick={() => onActiveChange("Portfolio")}>Portfolio</button>
                </li>




                {/* <li className="navbar-item">
                    <button className="navbar-link" data-nav-link>Blog</button>
                </li> */}

                <li className="navbar-item">
                    <button className={`navbar-link ${props.active === "Contact" ? "active" : ""}`} onClick={() => onActiveChange("Contact")}>Contact</button>
                </li>

            </ul>

        </nav>
    )
}

export default Navbar