import { React, useState } from 'react'
import { useScrollToTop } from '../hooks/useScrollToTop';
import autosky from "../assets/images/cover-autosky.jpeg";
import commercialQuest from "../assets/images/commercial-banner.jpeg";
import catholic from "../assets/images/catholic-banner.jpeg";
import majmua from "../assets/images/majmua-banner.jpeg";
import capiz from "../assets/images/capiz-banner.jpeg";

function Portfolio() {
    useScrollToTop();
    const [activeTab, setactive] = useState(false);

    const [selected, setSelected] = useState('All');
    let data = [
        { name: "AutoSky+ : GPS Tracking App", type: 'App', img: autosky, url: 'https://apps.apple.com/us/app/autosky/id6447604965' },
        { name: "CommercialQuest : Commercial Calculator", type: 'App', img: commercialQuest, url: "https://play.google.com/store/apps/details?id=com.commercial.commercialcalculator" },
        { name: "Catholic Connect", type: 'App', img: catholic, url: 'https://apps.apple.com/us/app/catholic-connect/id1433546806' },
        { name: "Majmua", type: 'App', img: majmua, url: 'https://play.google.com/store/apps/details?id=com.app.mukhatsar&hl=en_US' },
        { name: "Capiz", type: 'Web', img: capiz, url: 'http://capiz.fluttergeeks.in/' },
        // { name: "Demo2", type: 'Graphics', img: 'https://source.unsplash.com/featured/300x203' },
        // { name: "Demo3", type: 'Web', img: 'https://source.unsplash.com/featured/300x204' },
    ];


    let filteredItem = selected === "All" ? data : data.filter(item => (item.type.includes(selected) || item.type.includes('All')));

    let selectedTab = (params) => {
        setSelected(params);
    }

    return (
        <article className="portfolio active" data-page="portfolio">

            <header>
                <h2 className="h2 article-title">Portfolio</h2>
            </header>

            <section className="projects">

                <ul className="filter-list">

                    <li className="filter-item">
                        <button className={`${selected === "All" ? "active" : ""}`} onClick={() => selectedTab('All')}>All</button>
                    </li>
                    <li className="filter-item">
                        <button className={`${selected === "App" ? "active" : ""}`} onClick={() => selectedTab('App')}>Applications</button>
                    </li>
                    <li className="filter-item">
                        <button className={`${selected === "Web" ? "active" : ""}`} onClick={() => selectedTab('Web')}>Web Design</button>
                    </li>
                </ul>

                <div className="filter-select-box">

                    <button className={`filter-select ${activeTab === true ? "active" : ""}`} data-select onClick={() => setactive(!activeTab)}>
                        <div className="select-value" data-selecct-value>Select category</div>
                        <div className="select-icon">
                            <ion-icon name="chevron-down"></ion-icon>
                        </div>
                    </button>
                    <ul className="select-list">
                        <li className="select-item">
                            <button className={`${selected === "All" ? "active" : ""}`} onClick={() => { selectedTab('All'); setactive(false) }}>All</button>
                        </li>

                        <li className="select-item">
                            <button className={`${selected === "App" ? "active" : ""}`} onClick={() => { selectedTab('App'); setactive(false) }}>Applications</button>
                        </li>

                        <li className="select-item">
                            <button className={`${selected === "Web" ? "active" : ""}`} onClick={() => { selectedTab('Web'); setactive(false) }}>Web design</button>
                        </li>
                        {/* 


                        <li className="select-item">
                            <button data-select-item>Web development</button>
                        </li> */}

                    </ul>

                </div>

                <ul className="project-list">
                    {filteredItem.map(item => (
                        <li className="project-item  active" data-filter-item data-category="web development" key={item.name}>
                            <a href={item.url} target='_blank' rel="noopener noreferrer">

                                <figure className="project-img">
                                    <div className="project-item-icon-box">
                                        <ion-icon name="eye-outline"></ion-icon>
                                    </div>

                                    <img src={item.img} alt="finance" loading="lazy" />
                                </figure>

                                <h3 className="project-title">{item.name}</h3>

                                <p className="project-category">{item.type}</p>

                            </a>
                        </li>
                    ))}



                    {/* <li className="project-item  active" data-filter-item data-category="web development">
                        <a href="/">

                            <figure className="project-img">
                                <div className="project-item-icon-box">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </div>

                                <img src="./assets/images/project-2.png" alt="orizon" loading="lazy" />
                            </figure>

                            <h3 className="project-title">Orizon</h3>

                            <p className="project-category">Web development</p>

                        </a>
                    </li>

                    <li className="project-item  active" data-filter-item data-category="web design">
                        <a href="/">

                            <figure className="project-img">
                                <div className="project-item-icon-box">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </div>

                                <img src="./assets/images/project-3.jpg" alt="fundo" loading="lazy" />
                            </figure>

                            <h3 className="project-title">Fundo</h3>

                            <p className="project-category">Web design</p>

                        </a>
                    </li>

                    <li className="project-item  active" data-filter-item data-category="applications">
                        <a href="/">

                            <figure className="project-img">
                                <div className="project-item-icon-box">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </div>

                                <img src="./assets/images/project-4.png" alt="brawlhalla" loading="lazy" />
                            </figure>

                            <h3 className="project-title">Brawlhalla</h3>

                            <p className="project-category">Applications</p>

                        </a>
                    </li>

                    <li className="project-item  active" data-filter-item data-category="web design">
                        <a href="/">

                            <figure className="project-img">
                                <div className="project-item-icon-box">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </div>

                                <img src="./assets/images/project-5.png" alt="dsm." loading="lazy" />
                            </figure>

                            <h3 className="project-title">DSM.</h3>

                            <p className="project-category">Web design</p>

                        </a>
                    </li>

                    <li className="project-item  active" data-filter-item data-category="web design">
                        <a href="/">

                            <figure className="project-img">
                                <div className="project-item-icon-box">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </div>

                                <img src="./assets/images/project-6.png" alt="metaspark" loading="lazy" />
                            </figure>

                            <h3 className="project-title">MetaSpark</h3>

                            <p className="project-category">Web design</p>

                        </a>
                    </li>

                    <li className="project-item  active" data-filter-item data-category="web development">
                        <a href="/">

                            <figure className="project-img">
                                <div className="project-item-icon-box">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </div>

                                <img src="./assets/images/project-7.png" alt="summary" loading="lazy" />
                            </figure>

                            <h3 className="project-title">Summary</h3>

                            <p className="project-category">Web development</p>

                        </a>
                    </li>

                    <li className="project-item  active" data-filter-item data-category="applications">
                        <a href="/">

                            <figure className="project-img">
                                <div className="project-item-icon-box">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </div>

                                <img src="./assets/images/project-8.jpg" alt="task manager" loading="lazy" />
                            </figure>

                            <h3 className="project-title">Task Manager</h3>

                            <p className="project-category">Applications</p>

                        </a>
                    </li>

                    <li className="project-item  active" data-filter-item data-category="web development">
                        <a href="/">

                            <figure className="project-img">
                                <div className="project-item-icon-box">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </div>

                                <img src="./assets/images/project-9.png" alt="arrival" loading="lazy" />
                            </figure>

                            <h3 className="project-title">Arrival</h3>

                            <p className="project-category">Web development</p>

                        </a>
                    </li> */}

                </ul>

            </section>

        </article>
    )
}

export default Portfolio