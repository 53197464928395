
import './App.css';
import Pages from './components/Pages';
import Sidebar from './components/Sidebar';

function App() {
  return (

    <main>
      <Sidebar />
      <div className="main-content">
        <Pages />
      </div>
    </main>

  );
}

export default App;
