import { React, useState } from 'react'
import Navbar from './Navbar'
import About from './About'
import Resume from './Resume';
import Portfolio from './Portfolio';
import Skills from './Skills';
import Contact from './Contact';

function Pages() {
    const [active, activeState] = useState("About");

    let onPageChange = function onPageChangeState(newPage) {
        activeState(newPage);
    }
    return (
        <>
            <Navbar active={active} onPageChange={onPageChange} />
            {active === 'About' ? <About /> : active === 'Resume' ? <Resume active={active} /> : active === 'Portfolio' ? <Portfolio /> : active === 'Skills' ? <Skills Resume active={active} /> : active === 'Contact' ? <Contact active={active} /> : <></>}
        </>
    )
}

export default Pages