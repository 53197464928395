import { React } from 'react'
import iconDesign from '../assets/images/icon-design.svg';
import iconDev from '../assets/images/icon-dev.svg';
import graphicsDesign from '../assets/images/curve.svg';
import appIcon from '../assets/images/icon-app.svg';
import avatar from '../assets/images/avatar-1.png';
import { useScrollToTop } from '../hooks/useScrollToTop';


function About() {
    useScrollToTop();
    return (
        <article className="about  active" data-page="about">

            <header>
                <h2 className="h2 article-title">About me</h2>
            </header>

            <section className="about-text">
                <p>
                    I am Dhruvil Patel, a skilled developer proficient in a wide range of technologies, including Flutter, Dart,
                    Sockets, Rest APIs, and Firebase. While my expertise lies in app development, particularly with Flutter,
                    Dart, and Firebase, I'm also on a continuous journey to expand my horizons in web development. Currently,
                    I'm mastering technologies like Node.js, MongoDB, Express, EJS, HTML, CSS, JavaScript, and React.js to bring
                    a full-stack perspective to my projects. My commitment to clean code, robust architecture, and user-centric
                    design remains unwavering. Let's collaborate to create innovative solutions that span both the app and web
                    domains.
                </p>

                {/* <p>
                    My job is to turn ideas into tangible digital realities using a versatile skill set. As a professional app
                    developer, I specialize in crafting engaging and feature-rich mobile applications using technologies like
                    Flutter, Dart, Sockets, Rest APIs, and Firebase. I'm dedicated to creating software that not only functions
                    flawlessly but also provides a seamless user experience. I thrive on solving complex challenges and
                    delivering high-quality solutions that exceed
                    expectations. Whether it's building cutting-edge apps or developing responsive web experiences, I'm here to
                    bring your digital aspirations to life.
                </p> */}
            </section>


            {/* Services */}

            <section className="service">

                <h3 className="h3 service-title">What i'm doing</h3>

                <ul className="service-list">


                    <li className="service-item">

                        <div className="service-icon-box">
                            <img src={appIcon} alt="mobile app icon" width="40" />
                        </div>

                        <div className="service-content-box">
                            <h4 className="h4 service-item-title">Mobile apps</h4>

                            <p className="service-item-text">
                                Crafting engaging mobile apps with Flutter, Dart, Sockets, APIs, and Firebase.
                            </p>
                        </div>

                    </li>

                    <li className="service-item">

                        <div className="service-icon-box">
                            <img src={iconDesign} alt="design icon" width="40" />
                        </div>

                        <div className="service-content-box">
                            <h4 className="h4 service-item-title">Web design</h4>

                            <p className="service-item-text">
                                Designing user-friendly web interfaces using HTML, CSS, JavaScript, and React.js.
                            </p>
                        </div>

                    </li>

                    <li className="service-item">

                        <div className="service-icon-box">
                            <img src={iconDev} alt="Web development icon" width="40" />
                        </div>

                        <div className="service-content-box">
                            <h4 className="h4 service-item-title">Backend Development</h4>

                            <p className="service-item-text">
                                Building robust server-side solutions with Node.js, MongoDB, Express, and EJS.
                            </p>
                        </div>

                    </li>


                    <li className="service-item">

                        <div className="service-icon-box">
                            <img src={graphicsDesign} alt="camera icon" width="40" />
                        </div>

                        <div className="service-content-box">
                            <h4 className="h4 service-item-title">Graphics Design</h4>

                            <p className="service-item-text">
                                Creating captivating visuals to enhance digital projects.
                            </p>
                        </div>

                    </li>

                </ul>

            </section>


            {/* testimonials */}

            <section className="testimonials">

                <h3 className="h3 testimonials-title">Testimonials</h3>

                <ul className="testimonials-list has-scrollbar">



                    <li className="testimonials-item">
                        <div className="content-card" data-testimonials-item>

                            <figure className="testimonials-avatar-box">
                                <img src={avatar} alt="Henry william" width="60" data-testimonials-avatar />
                            </figure>

                            <h4 className="h4 testimonials-item-title" data-testimonials-title>Mani Arora</h4>

                            <div className="testimonials-text" data-testimonials-text>
                                <p>
                                    I highly recommend Dhruvil Patel for IT services. If you're looking for a professional who can turn your digital aspirations into reality and provide comprehensive IT solutions.
                                </p>
                            </div>

                        </div>
                    </li>

                </ul>

            </section>


            {/* testimonials modal */}


            {/* <div className="modal-container" data-modal-container>

                <div className="overlay" data-overlay></div>

                <section className="testimonials-modal">

                    <button className="modal-close-btn" data-modal-close-btn>
                        <ion-icon name="close-outline"></ion-icon>
                    </button>

                    <div className="modal-img-wrapper">
                        <figure className="modal-avatar-box">
                            <img src="./assets/images/avatar-1.png" alt="Daniel lewis" width="80" data-modal-img>
                        </figure>

                        <img src="./assets/images/icon-quote.svg" alt="quote icon">
                    </div>

                    <div className="modal-content">

                        <h4 className="h3 modal-title" data-modal-title>Daniel lewis</h4>

                        <time datetime="2021-06-14">14 June, 2021</time>

                        <div data-modal-text>
                            <p>
                                Richard was hired to create a corporate identity. We were very pleased with the work done. She has a
                                lot of experience
                                and is very concerned about the needs of client. Lorem ipsum dolor sit amet, ullamcous cididt
                                consectetur adipiscing
                                elit, seds do et eiusmod tempor incididunt ut laborels dolore magnarels alia.
                            </p>
                        </div>

                    </div>

                </section>

            </div> */}


            {/* clients */}

            {/* <section className="clients">

                <h3 className="h3 clients-title">Clients</h3>

                <ul className="clients-list has-scrollbar">

                    <li className="clients-item">
                        <a href="#">
                            <img src="./assets/images/logo-1-color.png" alt="client logo">
                        </a>
                    </li>

                    <li className="clients-item">
                        <a href="#">
                            <img src="./assets/images/logo-2-color.png" alt="client logo">
                        </a>
                    </li>

                    <li className="clients-item">
                        <a href="#">
                            <img src="./assets/images/logo-3-color.png" alt="client logo">
                        </a>
                    </li>

                    <li className="clients-item">
                        <a href="#">
                            <img src="./assets/images/logo-4-color.png" alt="client logo">
                        </a>
                    </li>

                    <li className="clients-item">
                        <a href="#">
                            <img src="./assets/images/logo-5-color.png" alt="client logo">
                        </a>
                    </li>

                    <li className="clients-item">
                        <a href="#">
                            <img src="./assets/images/logo-6-color.png" alt="client logo">
                        </a>
                    </li>

                </ul>

            </section> */}

        </article>
    )
}

export default About