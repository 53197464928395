import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sent from '../assets/images/sent.svg';
import { useScrollToTop } from '../hooks/useScrollToTop';

function Contact(props) {
    useScrollToTop();
    const form = useRef();
    const notify = () => toast.success("Email sent!", { theme: "dark", position: toast.POSITION.BOTTOM_RIGHT, icon: () => <img src={sent} alt='icon' /> });
    const notify2 = () => toast.error("Something Wrong", { theme: "dark", position: toast.POSITION.BOTTOM_RIGHT, });

    const sendEmail = (e) => {

        e.preventDefault();

        emailjs
            .sendForm('service_lxz662r', 'template_gks7zpl', form.current, 'eA5L0zxf0W0XOC6uC')
            .then(
                (result) => {
                    console.log(result.text);
                    notify();
                    e.target.reset();
                },
                (error) => {
                    notify2();

                    console.log(error.text);
                }
            );
    };

    return (
        <article className={`contact ${props.active === 'Contact' ? 'active' : ''}`} data-page="contact">
            <header>
                <h2 className="h2 article-title">Contact</h2>
            </header>

            <section className="mapbox" data-mapbox>
                <figure>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119066.41709470793!2d72.73989535424475!3d21.15934029855798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04e59411d1563%3A0xfe4558290938b042!2sSurat%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1693722179878!5m2!1sen!2sin"
                        width="400" height="300" loading="lazy" title='map'></iframe>
                </figure>
            </section>

            <section className="contact-form">
                <h3 className="h3 form-title">Contact Form</h3>

                <form ref={form} className="form" data-form onSubmit={sendEmail}> {/* Move onSubmit to the form */}
                    <div className="input-wrapper">
                        <input type="text" name="from_name" className="form-input" placeholder="Full name" required data-form-input />
                        <input type="email" name="from_email" className="form-input" placeholder="Email address" required data-form-input />
                    </div>

                    <textarea name="message" className="form-input" placeholder="Your Message" required data-form-input></textarea>

                    <button className="form-btn" type="submit" data-form-btn> {/* Remove onSubmit from the button */}
                        <ion-icon name="paper-plane"></ion-icon>
                        <span>Send Message</span>
                    </button>
                </form>
                <ToastContainer toastStyle={{ backgroundColor: "#1e1e1f" }} progressStyle={{ backgroundColor: "#ffdb70" }} />

            </section>
        </article>
    );
}

export default Contact;
