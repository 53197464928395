import { React, useState } from 'react'
import profile from '../assets/images/my-avatar.png';


function Sidebar() {
    const [active, setactive] = useState(false);
    return (
        <>
            <aside className={`sidebar ${active === true ? "active" : ""}`} data-sidebar>

                <div className="sidebar-info">

                    <figure className="avatar-box">
                        <img src={profile} alt="Dhruvil Patel" width="80" />
                    </figure>

                    <div className="info-content">
                        <h1 className="name" title="Dhruvil Patel">Dhruvil Patel</h1>

                        <p className="title">Full Stack Developer</p>
                    </div>

                    <button className="info_more-btn" onClick={() => setactive(!active)}>
                        <span>Show Contacts</span>

                        <ion-icon name="chevron-down"></ion-icon>
                    </button>

                </div>

                <div className="sidebar-info_more">

                    <div className="separator"></div>

                    <ul className="contacts-list">

                        <li className="contact-item">

                            <div className="icon-box">
                                <ion-icon name="mail-outline"></ion-icon>
                            </div>

                            <div className="contact-info">
                                <p className="contact-title">Email</p>

                                <a href="mailto:dhruvil.patel7419@gmail.com" className="contact-link">dhruvil.patel7419@gmail.com</a>
                            </div>

                        </li>

                        <li className="contact-item">

                            <div className="icon-box">
                                <ion-icon name="phone-portrait-outline"></ion-icon>
                            </div>

                            <div className="contact-info">
                                <p className="contact-title">Phone</p>

                                <a href="tel:+916354079848" className="contact-link">+91 63540 79848</a>
                            </div>

                        </li>

                        <li className="contact-item">

                            <div className="icon-box">
                                <ion-icon name="calendar-outline"></ion-icon>
                            </div>

                            <div className="contact-info">
                                <p className="contact-title">Birthday</p>

                                <time dateTime="1982-06-23">April 04, 2002</time>
                            </div>

                        </li>

                        <li className="contact-item">

                            <div className="icon-box">
                                <ion-icon name="location-outline"></ion-icon>
                            </div>

                            <div className="contact-info">
                                <p className="contact-title">Location</p>

                                <address>Surat, India</address>
                            </div>

                        </li>

                    </ul>

                    <div className="separator"></div>

                    <ul className="social-list">

                        <li className="social-item">
                            <a href="https://stackoverflow.com/users/19726949/dhruvil-patel" target='_blank' rel="noopener noreferrer" className="social-link">

                                <ion-icon className="socials-icons" name="logo-stackoverflow"></ion-icon>
                            </a>
                        </li>

                        <li className="social-item">
                            <a href="https://github.com/dhruvil444" target='_blank' rel="noopener noreferrer" className="social-link">
                                <ion-icon className="socials-icons" name="logo-github"></ion-icon>
                            </a>
                        </li>

                        <li className="social-item">
                            <a href="https://www.linkedin.com/in/dhruvil-patel-891221204/" target='_blank' rel="noopener noreferrer" className="social-link">

                                <ion-icon name="logo-linkedin"></ion-icon>
                            </a>
                        </li>

                        <li className="social-item">
                            <a href="https://www.instagram.com/flutter.geeks/" target='_blank' rel="noopener noreferrer" className="social-link">

                                <ion-icon name="logo-instagram"></ion-icon>
                            </a>
                        </li><li className="social-item">
                            <a href="https://wa.link/sz9aka" target='_blank' rel="noopener noreferrer" className="social-link">
                                <ion-icon name="logo-whatsapp"></ion-icon>
                            </a>
                        </li>

                        <li className="social-item">
                            <a href="https://twitter.com/Dhruvil13938732" target='_blank' rel="noopener noreferrer" className="social-link">
                                <ion-icon className="socials-icons" name="logo-twitter"></ion-icon>
                            </a>
                        </li>



                    </ul>

                </div>

            </aside>
        </>
    )
}

export default Sidebar