import React from 'react'
import { useScrollToTop } from '../hooks/useScrollToTop';
// import iconDesign from '../assets/images/icon-design.svg';

function Resume(props) {
    useScrollToTop();

    return (
        <article className={`resume ${props.active === "Resume" ? "active" : ""}`} data-page="resume">

            <header>
                <h2 className="h2 article-title">Resume</h2>
            </header>

            <section className="timeline">

                <div className="title-wrapper">
                    <div className="icon-box">
                        <ion-icon name="book-outline"></ion-icon>
                    </div>

                    <h3 className="h3">Education</h3>
                </div>

                <ol className="timeline-list">

                    <li className="timeline-item">

                        <h4 className="h4 timeline-item-title">Bachelor of Science in Information Technology</h4>
                        <p className="timeline-text">
                            Sarvepalli Radhakrishnan University
                        </p>

                        <span>2020-2023</span>



                    </li>

                    <li className="timeline-item">

                        <h4 className="h4 timeline-item-title">Higher Secondary Education (Science)</h4>
                        <p className="timeline-text">
                            Deep Darshan Vidhyasankul
                        </p>

                        <span>2018-2020</span>



                    </li>



                </ol>

            </section>

            <section className="timeline">

                <div className="title-wrapper">
                    <div className="icon-box">
                        <ion-icon name="book-outline"></ion-icon>
                    </div>

                    <h3 className="h3">Experience</h3>
                </div>

                <ol className="timeline-list">

                    <li className="timeline-item">

                        <h4 className="h4 timeline-item-title">Flutter Developer | Rentech Digital</h4>

                        <span>July 2022 — Present</span>

                        <p className="timeline-text">
                            In my role as a Flutter Developer at Rentech Digital, I lead projects and oversee the end-to-end development of mobile applications. This includes designing and developing apps using Flutter and Dart, integrating secure payment solutions, implementing new features, ensuring successful app deployment, and maintaining clear communication with clients to meet their needs and expectations. My experience in project leadership and mobile app development has allowed me to deliver high-quality solutions that enhance user experiences and drive client satisfaction.
                        </p>

                    </li>

                    <li className="timeline-item">

                        <h4 className="h4 timeline-item-title">Flutter Development Intern | Rentech Digital</h4>

                        <span>April 2022 - July 2022</span>

                        <p className="timeline-text">
                            During my internship at Rentech Digital, I gained valuable hands-on experience in Flutter app development. I collaborated with the team to work on real-world projects, contributing to app development tasks, implementing features, and gaining exposure to the development lifecycle. This internship allowed me to build a strong foundation in Flutter and Dart, further fueling my passion for mobile app development and setting the stage for my continued growth in the field.
                        </p>

                    </li>

                    <li className="timeline-item">

                        <h4 className="h4 timeline-item-title">Freelancer</h4>

                        <span>June 2021 - Present</span>

                        <p className="timeline-text">
                            As a freelancer, I've taken on a variety of projects spanning App Development, Web Design, and Graphics & Visuals, showcasing my versatility and expertise. This journey into independent contracting has allowed me to deliver tailored solutions to clients across different industries while continually expanding my skill set to meet evolving digital needs.
                        </p>

                    </li>

                </ol>

            </section>


            <section className="timeline">

                <div className="title-wrapper">
                    <div className="icon-box">
                        <ion-icon name="trophy"></ion-icon>
                    </div>

                    <h3 className="h3">Achievements</h3>
                </div>

                <ol className="timeline-list">
                    <li className="timeline-item">
                        <h4 className="h4 timeline-item-title">HTML Certification</h4>
                        <span>Date of Certification: 03 February, 2021</span>
                        <p className="timeline-text">
                            Certificate Link: <a href="https://www.sololearn.com/certificates/CT-TPRWVFMO" target='_blank' rel="noopener noreferrer" className="certificate orange-yellow-text">HTML Certificate</a>
                        </p>
                    </li>
                    <li className="timeline-item">
                        <h4 className="h4 timeline-item-title">JS Certification</h4>
                        <span>Date of Certification: 31 May, 2021</span>
                        <p className="timeline-text">
                            Certificate Link: <a href="https://www.sololearn.com/certificates/CT-RPHMQVJH" target='_blank' rel="noopener noreferrer" className="certificate orange-yellow-text">JS Certificate</a>
                        </p>
                    </li>
                </ol>

            </section>
            {/* <section className="skill">

                <h3 className="h3 skills-title">My skills</h3>

                <ul className="skills-list content-card">

                    <li className="skills-item">

                        <div className="title-wrapper">
                            <h5 className="h5">Web design</h5>
                            <data value="80">80%</data>
                        </div>

                        <div className="skill-progress-bg">
                            <div className="skill-progress-fill" style={{ width: "80%" }}></div>
                        </div>

                    </li>

                    <li className="skills-item">

                        <div className="title-wrapper">
                            <h5 className="h5">Graphic design</h5>
                            <data value="70">70%</data>
                        </div>

                        <div className="skill-progress-bg">
                            <div className="skill-progress-fill" style={{ width: "70%" }}></div>
                        </div>

                    </li>

                    <li className="skills-item">

                        <div className="title-wrapper">
                            <h5 className="h5">Branding</h5>
                            <data value="90">90%</data>
                        </div>

                        <div className="skill-progress-bg">
                            <div className="skill-progress-fill" style={{ width: "90%" }}></div>
                        </div>

                    </li>

                    <li className="skills-item">

                        <div className="title-wrapper">
                            <h5 className="h5">WordPress</h5>
                            <data value="50">50%</data>
                        </div>

                        <div className="skill-progress-bg">
                            <div className="skill-progress-fill" style={{ width: "50%" }}></div>
                        </div>

                    </li>

                </ul>

            </section> */}

        </article >
    )
}

export default Resume